import React from 'react';
import Layout from '../components/layout';
import PageHead from '../components/layout/page-head';
import ClientSection from '../components/pages/home/clients-section';
import CompanyDescription from '../components/pages/home/company-description';
import ExpertiseSection from '../components/pages/home/expertise-section';
import HeroImageSection from '../components/pages/home/hero-image-section';
import RequestConsultation from '../components/pages/home/request-consultation';

const Index = () => {
    return (
        <Layout>
            <HeroImageSection>
                <ExpertiseSection />
            </HeroImageSection>
            <ClientSection />
            <RequestConsultation />
            <CompanyDescription />
        </Layout>
    );
};

export const Head = () => <PageHead title='Home' />;

export default Index;
