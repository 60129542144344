import React, { HTMLAttributes } from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const HeroImageSection = ({ children }: HTMLAttributes<HTMLDivElement>) => {
    return (
        <section className='relative flex flex-col w-full items-center lg:pb-48 lg:mb-28'>
            <div className='relative flex flex-col items-center w-full h-screen lg:h-180'>
                <div className='absolute flex flex-col items-center justify-center w-full h-full bg-black bg-opacity-50 z-10'>
                    <div className='flex flex-col items-end max-w-7xl w-11/12 text-gray-50'>
                        <div className='text-6xl font-bold text-end md:text-7xl md:mb-4'>Building IOT Solutions</div>
                        <div className='text-2xl md:text-3xl'>For a better Malaysia</div>
                    </div>
                </div>
                <StaticImage
                    className='absolute w-full h-full z-0'
                    src='../../../images/equipment.png'
                    alt='equipment'
                    formats={['auto', 'avif', 'webp']}
                    loading='eager'
                />
            </div>
            {children}
        </section>
    );
};

export default HeroImageSection;
