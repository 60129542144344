import React, { HTMLAttributes } from 'react';
import { ClientLogo } from './clients-section';

type DesktopGridProps = {
    clients: ClientLogo[];
};

const ClientWrapper = ({ children }: HTMLAttributes<HTMLDivElement>) => {
    return <div className='flex items-center p-8'>{children}</div>;
};

const DesktopGrid = ({ clients }: DesktopGridProps) => {
    return (
        <div className='hidden max-w-7xl w-11/12 lg:grid lg:grid-cols-5 lg:gap-x-0 xl:gap-x-8'>
            {clients.map(client => {
                return <ClientWrapper key={client.id}>{client.img}</ClientWrapper>;
            })}
        </div>
    );
};

export default DesktopGrid;
