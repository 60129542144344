import React from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { GoPrimitiveDot } from 'react-icons/go';
import { ClientLogo } from './clients-section';
import classNames from 'classnames';

type MobileCarouselProps = {
    slides: ClientLogo[];
};

const MobileCarousel = ({ slides }: MobileCarouselProps) => {
    const [viewport_ref, embla] = useEmblaCarousel({ loop: false, align: 'center' });
    const [slide_list, setSlideList] = React.useState<number[]>([]);
    const [selected_index, setSelectedIndex] = React.useState(0);

    const nextSlide = React.useCallback(() => embla && embla.scrollNext(), [embla]);
    const prevSlide = React.useCallback(() => embla && embla.scrollPrev(), [embla]);
    const goToSlide = React.useCallback((index: number) => embla && embla.scrollTo(index), [embla]);
    const onSelectHandler = React.useCallback(() => {
        if (!embla) return;
        setSelectedIndex(embla.selectedScrollSnap());
    }, [embla, setSelectedIndex]);

    React.useEffect(() => {
        if (!embla) return;
        setSlideList(embla.scrollSnapList());
        embla.on('select', onSelectHandler);
    }, [embla]);

    return (
        <div className='relative overflow-hidden lg:hidden pb-10'>
            <div ref={viewport_ref} className=''>
                <div className='flex'>
                    {slides.map(({ id, img }) => {
                        return (
                            <div
                                key={id + '_carousel'}
                                className='inline-flex items-center justify-center grow-0 shrink-0 basis-full'
                            >
                                <div className='w-7/12 lg:w-full'>{img}</div>
                            </div>
                        );
                    })}
                </div>
            </div>
            <FiChevronLeft
                onClick={prevSlide}
                size='4rem'
                className={classNames('absolute text-slate-600 top-1/4', {
                    'opacity-20': selected_index < 1,
                    'opacity-50': selected_index > 0,
                })}
            />
            <FiChevronRight
                onClick={nextSlide}
                size='4rem'
                className={classNames('absolute text-slate-600 opacity-20 top-1/4 right-0', {
                    'opacity-20': selected_index >= slides.length - 1,
                    'opacity-50': selected_index < slides.length - 1,
                })}
            />
            <div className='absolute inline-flex w-full justify-center gap-x-1 z-20 bottom-0'>
                {slide_list.map((_slide, index) => (
                    <GoPrimitiveDot
                        key={`slide_${index}`}
                        size='1.2rem'
                        onClick={() => goToSlide(index)}
                        className={classNames({
                            'fill-red-600': index === selected_index,
                            'fill-gray-400': index !== selected_index,
                        })}
                    />
                ))}
            </div>
        </div>
    );
};

export default MobileCarousel;
