import React from 'react';
import { Link } from 'gatsby';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { GiBrain, GiCogLock } from 'react-icons/gi';
import { MdDesignServices } from 'react-icons/md';

const expertise_data = [
    {
        icon: <GiCogLock className='fill-gray-700' size='100%' />,
        title: 'System Design & Integration',
        description:
            "Proven track record of delivering customized IOT solutions that solve clients' specific problems and needs.",
    },
    {
        icon: <MdDesignServices className='fill-gray-700' size='100%' />,
        title: 'Design & 3D Printing',
        description:
            'We offer product design and 3D printing services for companies looking to bring their ideas to life.',
    },
    {
        icon: <GiBrain className='fill-gray-700' size='100%' />,
        title: 'Software & AI Development',
        description:
            'Custom software development and AI solutions for companies looking to drive innovation and growth.',
    },
];

type ExpertiseCardProps = {
    icon: JSX.Element;
    title: string;
    description: string;
};

const ExpertiseCard = ({ icon, title, description }: ExpertiseCardProps) => {
    return (
        <div className='flex flex-col items-center max-w-md mx-auto lg:w-full lg:m-0 bg-white rounded-lg shadow-lg hover:shadow-2xl transition-all p-6'>
            <div className='w-16 h-16 mb-6'>{icon}</div>
            <div className='w-full font-semibold text-center text-xl text-gray-800 border-red-400 border-b-2 pb-3 mb-3'>
                {title}
            </div>
            <div className='text-center text-gray-600'>{description}</div>
        </div>
    );
};

const ExpertiseSection = () => {
    return (
        <section className='max-w-7xl w-full py-12 z-10 md:block lg:w-11/12 lg:h-96 lg:absolute lg:bottom-0'>
            <div className='grid grid-rows-3 gap-y-8 gap-x-0 px-4 lg:grid-rows-1 lg:grid-cols-3 lg:gap-x-8 lg:gap-y-0 lg:w-11/12 lg:mb-12 mb-8 mx-auto'>
                {expertise_data.map(data => (
                    <ExpertiseCard key={data.title} {...data} />
                ))}
            </div>
            <div className='flex justify-center px-4'>
                <Link
                    className='inline-flex items-center px-6 py-4 rounded-lg cursor-pointer bg-gray-100 hover:bg-gray-200 transition-colors'
                    to='/services'
                >
                    <span className='font-semibold text-gray-900 text-xl mr-3'>See Full List of Our Services</span>
                    <AiOutlineArrowRight size='1.6rem' className='fill-red-500' />
                </Link>
            </div>
        </section>
    );
};

export default ExpertiseSection;
