import React from 'react';
import { BsChatRightQuoteFill } from 'react-icons/bs';
import { PHONE_NUMBER } from '../../../utils/constants';

const RequestConsultation = () => {
    return (
        <section className='w-full flex justify-center bg-gray-600 mb-20'>
            <div className='w-11/12 max-w-7xl items-center lg:flex py-6'>
                <div className='flex flex-col'>
                    <div className='text-white text-2xl font-bold'>Got any concerns?</div>
                    <div className='lg:text-xl text-gray-100 mb-4 lg:mb-0'>
                        Do you have a project in mind? Let us help. Contact us for a free consultation.
                    </div>
                </div>
                <a
                    href={`https://wa.me/${PHONE_NUMBER}?text=Hi%20there%2C%20I%20am%20interested%20in%20the%20services%20provided%20by%20Pacific%20Centric%20Sdn%20Bhd`}
                    className='inline-flex items-center bg-red-600 hover:bg-red-500 transition-all ease-in-out px-3 py-2 rounded ml-auto'
                >
                    <BsChatRightQuoteFill className='fill-gray-50 mr-2' />
                    <span className='text-gray-50 font-semibold'>Get Free a Consultation</span>
                </a>
            </div>
        </section>
    );
};

export default RequestConsultation;
