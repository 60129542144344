import { Link } from 'gatsby';
import React, { HTMLAttributes } from 'react';
import { BsPatchCheckFill } from 'react-icons/bs';
import { FaClipboardList } from 'react-icons/fa';
import ImageSwitcher from './image-switcher';

interface CheckedItemProps extends HTMLAttributes<HTMLDivElement> {}

const CheckedItem = ({ children }: CheckedItemProps) => {
    return (
        <li className='inline-flex items-center gap-x-2 mb-3'>
            <BsPatchCheckFill size='1.8rem' className='w-8 fill-red-500' />
            <div className='max-w-lg w-11/12 inline-flex items-center leading-none text-gray-700 font-semibold'>
                {children}
            </div>
        </li>
    );
};

export const CompanyDescription = () => {
    return (
        <section className='w-full flex justify-center mb-32'>
            <div className='w-full lg:inline-flex gap-x-12 max-w-7xl lg:w-11/12'>
                <ImageSwitcher />
                <div className='flex flex-col w-full px-4 lg:px-0 lg:w-1/2'>
                    <div className='font-semibold text-3xl text-gray-800 mb-6'>
                        Success with Pacific Centric's system integration
                    </div>
                    <div className='border-l-4 border-red-500 pl-8 mb-6'>
                        <div className='text-lg text-gray-700 max-w-lg text-justify leading-snug'>
                            We are a growing system integration, software development, and IoT company that provides
                            solutions to ensure our clients' success.
                        </div>
                    </div>
                    <ul className='flex flex-col mb-4'>
                        <CheckedItem>Custom software development</CheckedItem>
                        <CheckedItem>AI-powered solutions</CheckedItem>
                        <CheckedItem>Data analytics</CheckedItem>
                        <CheckedItem>System integration</CheckedItem>
                        <CheckedItem>Product design and 3D printing</CheckedItem>
                    </ul>
                    <Link to='/projects'>
                        <button className='inline-flex items-center w-fit text-gray-50 bg-red-600 hover:bg-red-500 px-5 h-12 rounded-lg transition-all ease-in-out'>
                            <FaClipboardList className='mr-2' size='1.4rem' />
                            <span className='font-semibold mt-0.5'>Explore our past projects</span>
                        </button>
                    </Link>
                </div>
            </div>
        </section>
    );
};
