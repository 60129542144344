import React, { HTMLAttributes } from 'react';
import classNames from 'classnames';
import { StaticImage } from 'gatsby-plugin-image';
import * as css from './image-switcher.module.css';

interface ImageWrapperProps extends HTMLAttributes<HTMLDivElement> {
    is_active: boolean;
}

const ImageWrapper = ({ children, is_active, ...props }: ImageWrapperProps) => {
    return (
        <div className={classNames(css.image_wrapper, { [css.is_active]: is_active })} {...props}>
            {children}
        </div>
    );
};

const ImageSwitcher = () => {
    const [active_image, setActiveImage] = React.useState(0);

    return (
        <div
            className={classNames(css.image_switcher, 'relative flex items-center justify-center lg:flex-col lg:w-1/2')}
        >
            <ImageWrapper is_active={active_image === 0} onClick={() => setActiveImage(0)}>
                <StaticImage
                    className='rounded-lg'
                    src='../../../../images/home/description/equipment.png'
                    alt='equipment installation'
                    height={490}
                />
            </ImageWrapper>
            <ImageWrapper is_active={active_image === 1} onClick={() => setActiveImage(1)}>
                <StaticImage
                    className='rounded-lg'
                    src='../../../../images/home/description/installation.png'
                    alt='equipment installation'
                    height={490}
                />
            </ImageWrapper>
        </div>
    );
};

export default ImageSwitcher;
