import { StaticImage } from 'gatsby-plugin-image';
import React, { ReactElement } from 'react';
import BoschLogo from '../../../../assets/home/bosch.svg';
import PetronasLogo from '../../../../assets/home/petronas.svg';
import DesktopGrid from './desktop-grid';
import MobileCarousel from './mobile-carousel';

export type ClientLogo = {
    id: string;
    img: ReactElement;
};

const clients: ClientLogo[] = [
    {
        id: 'sime_darby',
        img: (
            <StaticImage
                src='../../../../images/home/sime-darby.png'
                alt='sime darby logo'
                placeholder='none'
                loading='eager'
            />
        ),
    },
    {
        id: 'indah_water',
        img: (
            <StaticImage
                src='../../../../images/home/indah-water.png'
                alt='indah water logo'
                placeholder='none'
                loading='eager'
            />
        ),
    },
    {
        id: 'umt',
        img: (
            <StaticImage
                src='../../../../images/home/umt.png'
                alt='universiti malaysia terengganu logo'
                placeholder='none'
            />
        ),
    },
    { id: 'bosch', img: <BoschLogo className='w-full' alt='bosch logo' loading='eager' /> },
    { id: 'petronas', img: <PetronasLogo className='w-full' alt='petronas logo' loading='eager' /> },
];

export const ClientsSection = () => {
    return (
        <section className='flex flex-col items-center mb-12 py-6'>
            <div className='text-4xl uppercase font-bold text-gray-700 text-center mb-6 lg:mb-0'>
                Trusted by Top Industry Players
            </div>
            <DesktopGrid clients={clients} />
            <MobileCarousel slides={clients} />
        </section>
    );
};
